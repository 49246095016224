export const suppliersData = [
    {
      image: "/images/Suppliers/111.jpg",
      link:"http://www.mpmoran.co.uk/",
      title: "MP Moran & Sons",
    },
    {
        image: "/images/Suppliers/2.jpg",
        link:"http://www.travisperkins.co.uk/",
        title: "Travis Perkins",
    },
    {
        image: "/images/Suppliers/3.jpg",
        link:"http://www.builderdepot.co.uk/",
        title: "Builder Depot",
    },
    {
        image: "/images/Suppliers/19.jpg",
        link:"http://www.jewson.co.uk/",
        title: "Jewson",
    },
    {
        image: "/images/Suppliers/18.jpg",
        link:"http://www.bigbathcompany.com/main.html",
        title: "Big Bath Company",
    },
    {
        image: "/images/Suppliers/20.jpg",
        link:"http://fulhamtimber.co.uk/",
        title: "Fulham Timber",
    },
    {
        image: "/images/Suppliers/21.jpg",
        link:"http://www.structurals-ltd.co.uk/index.htm",
        title: "Structural Steelwork & Engineering",
    },
    {
        image: "/images/Suppliers/22.jpg",
        link:"http://www.cwplant.co.uk/",
        title: "Charles Wilson Engineers",
    },
    {
        image: "/images/Suppliers/23.jpg",
        link:"https://www.speedyservices.com/",
        title: "Speedy Services",
    },
    {
        image: "/images/Suppliers/24.jpg",
        link:"http://www.porcelanosa.com/uk/porcelanosa.php",
        title: "Porcelanosa UK",
    },
    {
        image: "/images/Suppliers/10.jpg",
        link:"http://www.nu-heat.co.uk/",
        title: "Nu-Heat",
    },
    {
        image: "/images/Suppliers/9.jpg",
        link:"http://www.elliottuk.com/hire",
        title: "Elliott Hire",
    }
  ];
