import React, {useState} from 'react';
import './NavMenu.scss';
import 'bootstrap/dist/css/bootstrap.css';
import  {NavLink } from "react-router-dom";

function NavMenu(){
    const [isNavCollapsed, setIsNavCollapsed] = useState(true);

    const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

    const handleLinkClick = () => setIsNavCollapsed(true); 
    return(
        <div className="navSection py-md-1 py-3">
            <div className="row align-items-center">
                <div className="col-8 col-lg-4">
                <NavLink  className="nav-link logo" to="/">
                    <img src={process.env.PUBLIC_URL + '/images/logo.png'} alt="" />
                </NavLink>
                </div>  
                <div className="col-4">
                    <nav className="navbar navbar-expand-lg navbar-light ">
                        <div className="container-fluid">                            
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded={!isNavCollapsed ? true : false} aria-label="Toggle navigation" onClick={handleNavCollapse}>
                            <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="navbarNav">
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                <NavLink  className="nav-link" to="/" onClick={handleLinkClick}>Home</NavLink>
                                </li>
                                <li className="nav-item">
                                <NavLink   className="nav-link" to="/about" onClick={handleLinkClick}>About</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink   className="nav-link" to="/projects" onClick={handleLinkClick}>Projects</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink   className="nav-link" to="/services" onClick={handleLinkClick}>Services</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink   className="nav-link" to="/suppliers" onClick={handleLinkClick}>Suppliers</NavLink>
                                </li>
                              
                            </ul>
                            </div>
                        </div>
                    </nav>
                </div>  
                <div className="col-4 d-none d-xl-block">
                <NavLink   className="nav-link" to="/about#contact-us" onClick={handleLinkClick}>CONTACT US</NavLink>
                </div> 
            </div>
        </div>
    );
}

export default NavMenu;