export const projectsData = [
    {
      id: "development-five-luxury-flats-ealing-w3",
      image: "/images/Cedar/497.jpg",
      title: "New Luxury Development: Five Exclusive Flats in Ealing, W3",
      location: "London",
      description: "Five luxury flats in Ealing W3, featuring elegant designs and premium finishes in a prime location.",
      button: "true",
    },
    {
        id: "development-of-nine-flats",
        image: "/images/Proj_2/286.jpg",
        title: "Exclusive New Development: Nine Luxury Flats in Golders Green, NW11",
        location: "London",
        description: "A new luxury development of nine premium flats in Golders Green, NW11, with high-end finishes and prime location.",
        button: "true",
      },
      {
        id: "conversion-of-an-office-building-in-four-luxury-flats-in-hammersmith",
        image: "/images/Proj_3/10.-960-lords-ext.webp",
        title: "Office Building Transformed into Four Luxury Flats in Hammersmith W6",
        location: "London",
        description: "A stylish office conversion into four luxury flats in Hammersmith W6, featuring modern interiors, high-end finishes, and superb comfort.",
        button: "true",
      },
      {
        id: "conversion-of-an-office-building-in-21-flats",
        image: "/images/Proj_4/483.jpg",
        title: "Office-to-Residential Conversion: 21 Modern Flats in NW2",
        location: "London",
        description: "A contemporary office-to-residential conversion offering 21 stylish flats in NW2, designed with modern finishes and convenient access to local amenities and transport links.",
        button: "true",
      },
      {
        id: "sw2-13",
        image: "/images/Proj_8/204.jpg",
        title: "Development of 4 News houses and an office building in SW2",
        location: "London",
        description: "Nestled in the vibrant heart of SW2, this exceptional new development offers a unique combination of residential charm and functional commercial space, designed to meet the demands of modern living and working in South West London. ",
        button: "true",
      },
      {
        id: "development-of-14-flats-and-commercial-12",
        image: "/images/Proj_10/175.jpg",
        title: "Development of 14 flats and commercial in HA8",
        location: "London",
        description: "Located in the vibrant and highly sought-after HA8 area, this exciting new development offers a unique and exceptional opportunity to live, work, and thrive in one of North West London’s most dynamic neighborhoods.",
        button: "true",
      },
      {
        id: "development-of-124-flats-retail-and-offices-with-underground-parking-18",
        image: "/images/Proj_16/265.jpg",
        title: "Development of 124 flats, retail and offices with underground parking",
        location: "Bistrita",
        description: "Located in a prime area, this large-scale development offers an exceptional combination of residential, commercial, and office spaces, making it an exciting and versatile investment opportunity.",
        button: "true",
      },
      {
        id: "development-of-a-hilton-hotel-and-an-office-building-19",
        image: "/images/Proj_17/276.jpg",
        title: "Development of a Hilton Hotel with 171 rooms and an office building of 19000ft2",
        location: "Cluj Napoca",
        description: "This exciting new development offers a unique and premium investment opportunity, combining luxury hospitality with modern office space in a highly sought-after location.",
        button: "true",
      },
      {
        id: "abroad--22",
        image: "/images/Proj_18/487.jpg",
        title: "Development of 13 detached houses",
        location: "Bistrita - Unirea",
        description: "This exclusive new development presents a rare opportunity to own one of 13 beautifully designed detached houses in a highly desirable location.",
        button: "true",
      },
      {
        id: "abroad-development-of-x-flats-24",
        image: "/images/Proj_19/Capture6.webp",
        title: "Development of 30 luxury apartments",
        location: "Cluj Napoca - Fagului",
        description: "A stylish office conversion into four luxury flats in Hammersmith W6, featuring modern interiors, high-end finishes, and superb comfort.",
        button: "true",
      },
      {
        id: "abroad-development-of-x-flats-25",
        image: "/images/Proj_20/494.jpg",
        title: "Development of 34 luxury apartments",
        location: "Cluj Napoca - Septimiu Albini",
        description: "A stylish office conversion into four luxury flats in Hammersmith W6, featuring modern interiors, high-end finishes, and superb comfort.",
        button: "true",
      },
      {
        id: "residential-flats-6",
        image: "/images/Proj_11/7.jpg",
        title: "Development of residential flats in E18",
        location: "London",
        description: "A stylish office conversion into four luxury flats in Hammersmith W6, featuring modern interiors, high-end finishes, and superb comfort.",
        button: "true",
      }
  ];


