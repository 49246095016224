import React from "react";
import { Helmet } from "react-helmet-async";
import {metaData} from "../../data/metaData.js";

const Meta = ({ page }) => {
  const { title, description } = metaData[page] || {};
  
  return (
    <Helmet>
      <title>{title || "Default Title"}</title>
      <meta name="description" content={description || "Default description"} />
    </Helmet>
  );
};

export default Meta;