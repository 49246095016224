
import './App.css';
import NavMenu from "../src/components/Navigation/NavMenu.js";
import FooterMenu from "../src/components/Navigation/FooterMenu.js";
import About from "../src/components/About.js";
import Home from "../src/components/Home.js";
import Projects from "../src/components/Projects.js";
import Project from './components/Cards/Project.js';
import Services from "../src/components/Services.js";
import Suppliers from "../src/components/Suppliers.js";
import ContactUs from "../src/components/ContactUs.js";
import ScrollToTop from "../src/components/blocks/ScrollToTop.js";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link
  } from "react-router-dom";

function App() {
    return ( 
        <Router>
            <div className = "App">
                <NavMenu/>
            </div>
            <ScrollToTop />
            <Routes>
                <Route exact path="/about" element={<About />}/>
                <Route exact path="/" element={<Home />}/> 
                <Route exact path="/projects" element={<Projects />}/>  
                <Route exact path="/projects/:id" element={<Project/>}/>
                <Route exact path="/services" element={<Services />}/>
                <Route exact path="/suppliers" element={<Suppliers />}/>
                <Route exact path="/contact" element={<ContactUs />}/>
            </Routes>
            <FooterMenu/>
     
        </Router>
    );
}

export default App;