import React from "react";
import "./Services.scss";
import Meta from "./blocks/Meta.js";

function Services(){

    return(
        <>
        <Meta page="home" />
        <div className="services">
            <div className="row justify-content-center contetnSection pt-5 pb-4">
                <div className="col-xl-12 col-lg-10 col-md-11 col-sm-11 col-11">
                    <div className="row justify-content-center contetnSection  pb-4">
                        <div className="col-xl-10 col-lg-12 col-md-11 col-sm-11 col-11 centerAlign px-0">
                            <div className="mt-5">
                                <div className="title pb-2">
                                    Services
                                </div>
                            </div>
                            <div className="description pb-2  mt-2">
                                At Ealing Developers, we provide comprehensive support for every stage of your property development journey. From initial design concepts to construction completion, our team ensures that each project is handled with precision and care. Our end-to-end services bring your vision to life while meeting all regulatory requirements and quality standards.                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="row justify-content-center contetnSection pt-5 pb-4 blackBG">
                <div className="col-xl-12 col-lg-10 col-md-11 col-sm-11 col-11">
                    <div className="row justify-content-center contetnSection  pb-4">
                        <div className="col-xl-4 col-lg-7 col-md-11 col-sm-11 col-11 d-flex justify-content-center imageBannerBlack">
                            <img src={process.env.PUBLIC_URL + '/images/Proj_4/325.jpg'} alt="" />
                        </div>
                        <div className="col-xl-8 col-lg-7 col-md-11 col-sm-11 col-11 d-flex justify-content-center imageBannerBlack">
                        <img src={process.env.PUBLIC_URL + '/images/Proj_4/334.jpg'} alt="" />
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="row justify-content-center contetnSection pt-md-5 pt-3 pb-4">
                <div className="col-xl-10 col-lg-10 col-md-11 col-sm-11 col-11">
                    <div className="row justify-content-center contetnSection  pb-4">
                        <div className="col-xl-3 col-lg-3 col-md-11 col-sm-11 col-11 position-relative">
                            <div className="leftCategorySection  pb-2">
                                <div className="leftCategoryContent  pb-2">
                                    <div className="leftCategoryTitle  pb-2">
                                        Services included in every plan
                                    </div>

                                    <div className="descService pb-2 mt-md-5 mt-4">
                                    We offer transparent pricing and will provide a detailed proposal after assessing your project requirements.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-9 col-lg-9 col-md-11 col-sm-11 col-11 justify-content-end">
                            <div className="row justify-content-center justify-content-lg-start contetnSection  pb-4 services-section">
                                <div className="col-xl-5 col-lg-5 col-md-5 col-sm-11 col-12 service-place">
                                    <div className="service-element">
                                        <div className="titleService pb-2 mt-5">
                                            01/<br />
                                            DESIGN
                                        </div>

                                        <div className="titleServiceBold pb-2">
                                            Comprehensive Architectural and Interior Design Services
                                        </div>

                                        <div className="descService pt-4 pb-2">
                                            Our design team creates functional, beautiful spaces tailored to each project, providing architectural, structural, and interior design with a focus on sustainability, efficiency, and aesthetics.
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-5 col-lg-5 col-md-5 col-sm-11 col-12 service-place">
                                    <div className="service-element">
                                        <div className="titleService pb-2 mt-5">
                                            02/<br />
                                            Quantity Surveying
                                        </div>

                                        <div className="titleServiceBold pb-2">
                                            Detailed Cost Estimation and Quantity Surveying Services
                                        </div>

                                        <div className="descService pt-4 pb-2">
                                            Our Quantity Surveying services ensure cost efficiency with accurate budgeting, cost control, and financial forecasting. We manage procurement and risks to keep projects on budget without compromising quality.
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-5 col-lg-5 col-md-5 col-sm-11 col-12 service-place" >
                                    <div className="service-element">
                                        <div className="titleService pb-2 mt-5">
                                            03/<br />
                                            Project Management
                                        </div>

                                        <div className="titleServiceBold pb-2">
                                            Comprehensive Project Management and Coordination Services
                                        </div>

                                        <div className="descService pt-4 pb-2">
                                            Our Project Management team coordinates all development stages, ensuring seamless progress through scheduling, resource management, and quality control. We keep clients updated on milestones, proactively addressing issues to meet timelines and goals.
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-5 col-lg-5 col-md-5 col-sm-11 col-12 service-place">
                                    <div className="service-element">
                                        <div className="titleService pb-2 mt-5">
                                            04/<br />
                                            Obtaining Planning Permissions
                                        </div>

                                        <div className="titleServiceBold pb-2">
                                            Expert Planning Permission Consultation and Approval Services
                                        </div>

                                        <div className="descService pt-4 pb-2">
                                            Our team simplifies the planning permission process with expertise in local regulations. We handle document preparation, liaise with authorities, and represent clients in consultations to ensure smooth, timely approvals.
                                        </div>
                                    </div>
                                </div>
                  
                                <div className="col-xl-5 col-lg-5 col-md-5 col-sm-11 col-12 service-place">
                                    <div className="service-element">
                                        <div className="titleService pb-2 mt-5">
                                            06/<br />
                                            Full Construction Services
                                        </div>

                                        <div className="titleServiceBold pb-2">
                                            End-to-End Construction and Development Execution Services
                                        </div>

                                        <div className="descService pt-4 pb-2">
                                            We manage all Building Control approvals, preparing documentation, ensuring compliance with safety standards, and coordinating inspections. This service guarantees that projects meet all national regulations for safety and compliance.
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-5 col-lg-5 col-md-5 col-sm-11 col-12 service-place">
                                    <div className="service-element">
                                        <div className="titleService pb-2 mt-5">
                                            07/<br />
                                            10-Year Warranty
                                        </div>

                                        <div className="titleServiceBold pb-2">
                                            10-Year Structural and System Warranty
                                        </div>

                                        <div className="descService pt-4 pb-2">
                                            We provide a 10-year warranty on all developments, covering structural defects, waterproofing, and major systems like electrical and plumbing, ensuring lasting quality and peace of mind.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        

                        
                    </div>
                </div>
            </div>
            
        </div>
        </>
    );
}

export default Services