// MapComponent.js
import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';  // Import Leaflet
// Set the default center position and zoom level
const center = [51.614132079662085, -0.24857594424179585]; // Latitude and Longitude (example: San Francisco)

const MapComponent = () => {

    const customIcon = new L.Icon({
        iconUrl: '/images/location-pin-icon-map-pin-place-marker-png.png', // Replace with your custom marker image URL
        iconSize: [50, 50], // Size of the icon (width, height)
        iconAnchor: [25, 50], // Position of the icon anchor (relative to the icon's top-left corner)
        popupAnchor: [0, -50], // Position of the popup relative to the icon
    });
    return (
        <MapContainer center={center} zoom={15} style={{ width: '100%', height: '400px' }}>
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            {/* Optional: Add a marker */}
            <Marker position={center} icon={customIcon}>
                <Popup>A marker in San Francisco!</Popup>
            </Marker>
        </MapContainer>
    );
};

export default MapComponent;