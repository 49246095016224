
  export const projectElements=
  {
    "development-five-luxury-flats-ealing-w3":{
        title:"New Luxury Development: Five Exclusive Flats in Ealing, W3",
        link:"http://www.366ealingcommon.com",
        location: "Ealing, W3, London",
        type: "Luxury Residential Flats",
        units: "5 Exclusive Apartments",
        description:"Situated in the heart of Ealing W3, this boutique development offers five luxury flats, each designed with the highest attention to detail and modern living in mind. These exclusive residences combine contemporary architecture with elegant finishes, providing a perfect blend of style, comfort, and functionality. Each flat boasts spacious open-plan living areas, state-of-the-art kitchens with premium appliances, and large windows that flood the rooms with natural light. High-end fixtures, underfloor heating, and private balconies enhance the luxurious feel of the homes, while smart home technology ensures convenience and efficiency. Located in a sought-after neighborhood, the development is just minutes away from Ealing’s vibrant shopping districts, green spaces, and excellent transport links, making it ideal for professionals and families alike.",
        brochure:"",
        gallery: [
            {
                original: "/images/Cedar/497.jpg",
                thumbnail: "/images/Cedar/497.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Cedar/498.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Cedar/498.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Cedar/499.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Cedar/499.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Cedar/500.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Cedar/500.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Cedar/501.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Cedar/501.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Cedar/502.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Cedar/502.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Cedar/504.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Cedar/504.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Cedar/505.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Cedar/505.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Cedar/506.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Cedar/506.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Cedar/507.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Cedar/507.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Cedar/508.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Cedar/508.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Cedar/509.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Cedar/509.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Cedar/510.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Cedar/510.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Cedar/511.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Cedar/511.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Cedar/512.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Cedar/512.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Cedar/513.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Cedar/513.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Cedar/514.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Cedar/514.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Cedar/515.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Cedar/515.jpg"
            }
        ]
    },
    "development-of-nine-flats":{
        title:"Exclusive New Development: Nine Luxury Flats in Golders Green, NW11",
        link:"http://www.366ealingcommon.com",
        location: "London",
        type: "Luxury Residential Flats",
        units: "9 Exclusive Flats",
        description:"Situated in the vibrant heart of Golders Green, one of North West London’s most desirable and distinguished neighborhoods, this extraordinary new development presents a rare opportunity to embrace modern luxury living in an area celebrated for its charm and exclusivity. Thoughtfully crafted to the highest standards, this boutique collection comprises nine exquisitely designed apartments, each offering a unique blend of contemporary sophistication and timeless elegance. Every detail of this development has been meticulously considered to create homes that are both functional and luxurious. From the striking architectural design that commands attention to the high-spec interiors that exude quality, this is a residence that truly stands out. The apartments have been designed to cater to modern lifestyles, providing light-filled spaces, exceptional comfort, and an undeniable sense of style. ",
        brochure:"",
        gallery:[
            {
                original: process.env.PUBLIC_URL + "/images/Proj_2/286.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_2/286.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_2/287.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_2/287.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_2/292.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_2/292.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_2/344.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_2/344.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_2/347.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_2/347.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_2/349.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_2/349.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_2/350.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_2/350.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_2/351.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_2/351.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_2/353.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_2/353.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_2/360.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_2/360.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_2/361.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_2/361.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_2/476.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_2/476.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_2/485.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_2/485.jpg"
            }
        ]
    },
    "conversion-of-an-office-building-in-four-luxury-flats-in-hammersmith":{
        title:"Office Building Transformed into Four Luxury Flats in Hammersmith W6",
        link:"http://www.366ealingcommon.com",
        location: "London",
        type: "Luxury Residential Flats",
        units: "4 Exclusive Flats",
        description:"A former office building in the heart of vibrant Hammersmith has undergone a remarkable transformation, reimagined as four exquisite luxury flats that epitomize modern urban living. Nestled within the desirable W6 postcode, this exceptional development marries contemporary design with the dynamic lifestyle of one of London’s most sought-after neighborhoods. The transformation of this property is a testament to innovative architecture and meticulous craftsmanship, resulting in four distinct residences that offer unparalleled sophistication and comfort. Each flat has been thoughtfully designed to harmonize style and functionality, featuring spacious, open-plan layouts that maximize natural light and create a seamless flow throughout the living areas. High ceilings and bespoke finishes add an air of elegance, while premium fixtures and state-of-the-art appliances ensure a luxurious and practical living experience. ",
        brochure:"",
        gallery:[
            {
                original: process.env.PUBLIC_URL + "/images/Proj_3/424.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_3/424.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_3/366.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_3/366.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_3/363.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_3/363.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_3/423.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_3/423.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_3/457.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_3/457.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_3/464.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_3/464.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_3/469.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_3/469.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_3/470.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_3/470.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_3/473.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_3/473.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_3/474.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_3/474.jpg"
            }
        ]
    },
    "conversion-of-an-office-building-in-21-flats":{
        title:"Office-to-Residential Conversion: 21 Modern Flats in NW2",
        link:"http://www.366ealingcommon.com",
        location: "London",
        type: "Luxury Residential Flats",
        units: "21 Modern Flats",
        description:"A former office building in the vibrant NW2 area has been meticulously transformed into a collection of 21 exceptional modern flats, offering a rare chance to own a stylish, thoughtfully designed home in one of North West London’s most dynamic neighborhoods. This innovative development showcases the perfect synergy of urban convenience and contemporary design, catering to a diverse range of lifestyles. Each flat has been carefully planned to create a comfortable and functional living environment while maintaining an emphasis on modern aesthetics and high-quality craftsmanship. The transformation highlights the best of adaptive architecture, turning a commercial space into a residential haven that meets the demands of today’s city living.",
        brochure:"",
        gallery:[
            {
                original: process.env.PUBLIC_URL + "/images/Proj_4/316.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_4/316.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_4/318.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_4/318.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_4/321.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_4/321.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_4/320.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_4/320.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_4/327.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_4/327.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_4/329.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_4/329.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_4/331.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_4/331.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_4/334.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_4/334.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_4/482.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_4/482.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_4/484.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_4/484.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_4/483.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_4/483.jpg"
            }
        ]
    },
    "conversion-of-an-warehouse-office-building-in-9-luxury-flats":{
        title:"Conversion of an warehouse/office building in 9 luxury flats in Angel Islington N1",
        link:"http://www.366ealingcommon.com",
        location: "London",
        description:"",
        brochure:"",
        gallery:[
            {
                original: process.env.PUBLIC_URL + "/images/Proj_5/453.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_5/453.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_5/426.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_5/426.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_5/446.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_5/446.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_5/454.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_5/454.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_5/451.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_5/451.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_5/449.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_5/449.jpg"
            }
        ]
    },
    "conversion-of-an-office-building-in-25-flats-in-ha8-15":{
        title:"Conversion of an office building in 25 flats in HA8",
        link:"http://www.366ealingcommon.com",
        location: "London",
        description:"",
        brochure:"",
        gallery:[
            {
                original: process.env.PUBLIC_URL + "/images/Proj_6/215.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_6/215.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_6/216.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_6/216.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_6/217.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_6/217.jpg"
            }
        ]
    },
    "sw2-13":{
        title:"Development of 4 News houses and an office building in SW2",
        link:"http://www.366ealingcommon.com",
        location: "London",
        type: "Luxury Residential Flats",
        units: "4 houses",
        description:"Nestled in the vibrant heart of SW2, this exceptional new development offers a unique combination of residential charm and functional commercial space, designed to meet the demands of modern living and working in South West London. The development features four meticulously crafted news houses, each with its own distinctive architectural style and layout, alongside a modern office building. This blend of residential and commercial spaces makes it an ideal opportunity for those seeking a harmonious lifestyle in a highly sought-after area. This development represents an extraordinary opportunity for those looking to live and work in one of London’s most sought-after locales. With its exceptional design, prime location, and thoughtfully integrated residential and commercial spaces, it offers the perfect blend of comfort, convenience, and style for those who want the best of both worlds.",
        brochure:"",
        gallery:[
            {
                original: process.env.PUBLIC_URL + "/images/Proj_8/204.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_8/204.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_8/207.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_8/207.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_8/208.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_8/208.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_8/257.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_8/257.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_8/259.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_8/259.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_8/256.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_8/256.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_8/235.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_8/235.jpg"
            }
        ]
    },
    "conversion-of-an-office-building-in-25-flats-in-ha3-17":{
        title:"Conversion of an office building in 25 flats in HA3",
        link:"http://www.366ealingcommon.com",
        location: "London",
        description:"",
        brochure:"",
        gallery:[
            {
                original: process.env.PUBLIC_URL + "/images/Proj_9/218.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_9/218.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_9/216.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_9/216.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_9/217.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_9/217.jpg"
            }
        ]
    },
    "development-of-14-flats-and-commercial-12":{
        title:"Development of 14 flats and commercial in HA8",
        link:"http://www.366ealingcommon.com",
        location: "London",
        type: "Luxury Residential and Comercial Flats",
        units: "14 Exclusive Flats",
        description:"Located in the vibrant and highly sought-after HA8 area, this exciting new development offers a unique and exceptional opportunity to live, work, and thrive in one of North West London’s most dynamic neighborhoods. Combining the best of modern residential living with functional commercial space, this development is ideal for both homeowners and businesses alike. The project features 14 contemporary flats that provide stylish and comfortable living, along with versatile commercial units that cater to a variety of business needs, from retail to office spaces, or even food and beverage outlets. The flats have been carefully designed to maximize space and natural light, offering a selection of one, two, and three-bedroom layouts to suit a range of lifestyles. Each apartment boasts spacious open-plan living areas that blend seamlessly with high-spec kitchens and modern bathrooms, ensuring the utmost convenience and style. Large windows create bright, airy interiors, while high-end finishes and integrated appliances elevate the standard of living. For added convenience and luxury, some flats also feature private balconies or terraces, offering residents their own outdoor retreat to unwind or entertain.",
        brochure:"",
        gallery:[
            {
                original: process.env.PUBLIC_URL + "/images/Proj_10/175.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_10/175.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_10/181.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_10/181.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_10/185.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_10/185.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_10/184.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_10/184.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_10/187.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_10/187.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_10/196.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_10/196.jpg"
            }
        ]
    },
    "residential-flats-6":{
        title:"Development of residential flats in E18",
        link:"http://www.366ealingcommon.com",
        location: "London",
        type: "Luxury Residential Flats",
        units: "Exclusive Flats",
        description:"Nestled in the desirable E18 area, this new residential development offers a unique opportunity to experience modern urban living in one of East London's most sought-after locations. Designed with both comfort and style in mind, this project features a selection of beautifully crafted flats that provide the perfect balance of contemporary design, practicality, and convenience. Whether you are a first-time buyer, a young professional, or a growing family, this development caters to a wide range of lifestyles, making it an ideal place to call home. The development includes a variety of one, two, and three-bedroom flats, each thoughtfully designed to offer spacious, open-plan layouts that make the most of the available space. Expansive living areas are bathed in natural light, thanks to large windows that provide stunning views of the surrounding neighborhood. Every flat has been designed to create a bright, airy, and inviting atmosphere, with sleek, modern finishes and high-quality materials throughout. The open-plan living and dining areas flow seamlessly into fully equipped kitchens, which feature the latest in integrated appliances, sleek countertops, and stylish cabinetry.",
        brochure:"",
        gallery:[
            {
                original: process.env.PUBLIC_URL + "/images/Proj_11/7.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_11/7.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_11/8.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_11/8.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_11/9.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_11/9.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_11/10.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_11/10.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_11/14.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_11/14.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_11/13.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_11/13.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_11/15.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_11/15.jpg"
            }
        ]
    },
    "officebuilding-1":{
        title:"Office building and parking in HA8",
        link:"http://www.366ealingcommon.com",
        location: "London",
        type: "Office and Parking",
        units: "-",
        description:"Located in the thriving HA8 area, this exciting new development offers a premium office building combined with ample parking facilities, making it an ideal opportunity for businesses looking to establish themselves in one of North West London’s most dynamic neighborhoods. Designed to cater to modern business needs, this office space is built to provide a professional, productive, and flexible environment for a variety of industries. The office building itself has been designed with functionality and sophistication in mind, offering a range of spaces to suit different business sizes and requirements. With a contemporary, open-plan layout, the offices are equipped with high-quality finishes, modern facilities, and large windows that flood the interiors with natural light, creating a bright and welcoming atmosphere. The flexible layout allows for easy customization, enabling businesses to adapt the space to meet their specific needs, whether for collaborative workspaces, private offices, or meeting rooms.",
        brochure:"",
        gallery:[
            {
                original: process.env.PUBLIC_URL + "/images/Proj_12/5.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_12/5.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_12/2.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_12/2.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_12/3.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_12/3.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_12/4.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_12/4.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_12/6.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_12/6.jpg"
            }
        ]
    },
    "deveopment-of-12-flats-in-n7-10":{
        title:"Development of 12 flats in N7",
        link:"http://www.366ealingcommon.com",
        location: "London",
        description:"",
        brochure:"",
        gallery:[
            {
                original: process.env.PUBLIC_URL + "/images/Proj_13/137.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_13/137.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_13/139.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_13/139.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_13/140.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_13/140.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_13/141.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_13/141.jpg"
            }
        ]
    },
    "six-mews-houses-in-nw6-7":{
        title:"Development of six news houses in NW6",
        link:"http://www.366ealingcommon.com",
        description:"",
        brochure:"",
        gallery:[
            {
                original: process.env.PUBLIC_URL + "/images/Proj_14/16.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_14/16.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_14/17.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_14/17.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_14/18.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_14/18.jpg"
            }
        ]
    },
    "development-of-27-flats-and-houses-in-n19-11":{
        title:"Development of 27 flats and houses in N19",
        link:"http://www.366ealingcommon.com",
        location: "London",
        description:"",
        brochure:"",
        gallery:[
            {
                original: process.env.PUBLIC_URL + "/images/Proj_15/143.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_15/143.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_15/145.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_15/145.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_15/144.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_15/144.jpg"
            }
        ]
    },
    "development-of-124-flats-retail-and-offices-with-underground-parking-18":{
        title:"Development of 124 flats, retail and offices with underground parking",
        link:"http://www.366ealingcommon.com",
        location: "London",
        type: "Retail, Offices, Parking",
        units: "124 Flats",
        description:"Located in a prime area, this large-scale development offers an exceptional combination of residential, commercial, and office spaces, making it an exciting and versatile investment opportunity. With a total of 124 thoughtfully designed flats, modern retail units, flexible office spaces, and ample underground parking, this project provides the ideal solution for those seeking a balanced and dynamic lifestyle in a thriving urban environment.",
        brochure:"",
        gallery:[
            {
                original: process.env.PUBLIC_URL + "/images/Proj_16/265.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_16/265.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_16/261.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_16/261.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_16/263.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_16/263.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_16/266.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_16/266.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_16/267.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_16/267.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_16/268.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_16/268.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_16/273.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_16/273.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_16/269.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_16/269.jpg"
            }
        ]
    },
    "development-of-a-hilton-hotel-and-an-office-building-19":{
        title:"Development of a Hilton Hotel with 171 rooms and an office building of 19000ft2",
        link:"http://www.366ealingcommon.com",
        location: "London",
        type: "Hotel, Office",
        units: "124 rooms",
        description:"This exciting new development offers a unique and premium investment opportunity, combining luxury hospitality with modern office space in a highly sought-after location. Featuring a Hilton-branded hotel with 171 elegantly designed rooms and a state-of-the-art office building spanning 19,000 square feet, this project provides a versatile mix of accommodations and commercial space to meet the needs of both business and leisure travelers, as well as companies seeking a prime office location.",
        brochure:"",
        gallery:[
            {
                original: process.env.PUBLIC_URL + "/images/Proj_17/276.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_17/276.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_17/277.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_17/277.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_17/278.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_17/278.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_17/280.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_17/280.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_17/283.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_17/283.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_17/284.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_17/284.jpg"
            }
        ]
    },
    "abroad--22":{
        title:"Development of 13 detached houses",
        link:"http://www.366ealingcommon.com",
        location: "Romania, Bistrita",
        type: "Rezidential",
        units: "13 houses",
        description:"This exclusive new development presents a rare opportunity to own one of 13 beautifully designed detached houses in a highly desirable location. Offering the perfect blend of privacy, luxury, and modern design, these homes have been crafted to provide spacious, comfortable living with a focus on quality, style, and functionality. Whether you're looking for a family home, a peaceful retreat, or a long-term investment, these detached houses offer everything you need for contemporary living.",
        brochure:"",
        gallery:[
            {
                original: process.env.PUBLIC_URL + "/images/Proj_18/487.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_18/487.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_18/486.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_18/486.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_18/488.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_18/488.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_18/489.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_18/489.jpg"
            }
        ]
    },
    "abroad-development-of-x-flats-24":{
        title:"Development of 30 luxury apartments",
        link:"http://www.366ealingcommon.com",
        location: "Romania, Cluj-Napoca",
        type: "Rezidential",
        units: "30 Apartments",
        description:"This prestigious new development offers an exclusive opportunity to own one of 30 luxury apartments in a highly sought-after location. Designed for those who appreciate the finest things in life, these apartments combine contemporary elegance, state-of-the-art amenities, and exceptional craftsmanship to create a living experience that is second to none. Whether you are a first-time buyer, a seasoned investor, or someone looking for an upgrade, these luxury apartments cater to a wide range of discerning tastes and lifestyles.",
        brochure:"",
        gallery:[
            {
                original: process.env.PUBLIC_URL + "/images/Proj_19/Capture6.webp",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_19/Capture6.webp"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_19/491.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_19/491.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_19/500.png",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_19/500.png"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_19/Capture7.webp",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_19/Capture7.webp"
            }
        ]
    },
    "abroad-development-of-x-flats-25":{
        title:"Development of 34 luxury apartments",
        link:"http://www.366ealingcommon.com",
        location: "Romania, Cluj-Napoca",
        type: "Rezidential",
        units: "34 Apartments",
        description:"This exceptional new development in the heart of Cluj-Napoca, one of Romania’s most dynamic and vibrant cities, offers a rare opportunity to own a luxury apartment in a prime location. Comprising 34 meticulously designed apartments, this project combines modern design, high-end finishes, and state-of-the-art amenities, making it the ideal choice for those seeking a sophisticated and contemporary living experience in one of the country’s most sought-after cities.",
        brochure:"",
        gallery:[
            {
                original: process.env.PUBLIC_URL + "/images/Proj_20/494.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_20/494.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_20/492.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_20/492.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_20/493.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_20/493.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_20/495.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_20/495.jpg"
            },
            {
                original: process.env.PUBLIC_URL + "/images/Proj_20/496.jpg",
                thumbnail: process.env.PUBLIC_URL + "/images/Proj_20/496.jpg"
            }
        ]
    }
  };



