import './ContactUs.scss';
import Meta from "./blocks/Meta.js";
function ContactUs() {
    return ( 
        <>
        <Meta page="contact" />
        <div className = "contactUs">

            <div className = "row justify-content-center contetnSection pt-5 pb-4">
                <div className = "col-xl-7 col-lg-10 col-md-11 col-sm-11 col-11">
                    <div className = "row justify-content-center contetnSection pt-5 pb-4">
                        <div className = "col-xl-7 col-lg-7 col-md-11 col-sm-11 col-11">
                            <div className="title pb-2">
                                About Ealing Developers
                            </div>
                            <div className='description'>
                                <b>Developer and builder approved by Premier Guarantee and Build Zone Warranty providers! We are providing 10 year warranty to all our new build projects.</b>
                            </div>
                            <div className = "row justify-content-center contetnSection pb-3 pt-5">
                                <div className = "col-4">
                                    <a target="_blank" href="http://www.premierguarantee.co.uk/" rel="noreferrer"><img src={process.env.PUBLIC_URL + '/images/pg.png'} alt=""/></a>
                                </div>
                                <div className = "col-4">
                                    <a target="_blank" href="http://www.premierguarantee.co.uk/" rel="noreferrer"><img src={process.env.PUBLIC_URL + '/images/pgS.png'} alt=""/></a>
                                </div>
                                <div className = "col-4">
                                    <a target="_blank" href="http://www.premierguarantee.co.uk/" rel="noreferrer"><img src={process.env.PUBLIC_URL + '/images/buildz.gif'} alt=""/></a>
                                </div>
                            </div>
                        </div>
                        <div className = "col-xl-5 col-lg-5 col-md-11 col-sm-11 col-11 p-4">
                            <div className='aboutLink mb-4 pt-4'>
                                <strong>About Ealing Developers</strong>
                            </div>
                            <div className='aboutDetails'>
                                <div className='aboutContact pb-3'> 
                                    Contact details
                                </div>
                                <div className='aboutDescription'>
                                <p><b>Ealing Developers Ltd</b><br/>Reg no: 10660181 <br/>Reg address:<br/>Suite 4, Mill Hill House<br/>6 The Broadway<br/>London<br/>NW7 3LL<br/>E: <b><a href="mailto:manager@ealingdevelopers.com">manager@ealingdevelopers.com</a> </b></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        </>
    );
}

export default ContactUs;