import React from "react";
import './Projects.scss';
import {projectsData} from "../data/projects.js"
import  {NavLink } from "react-router-dom";
import Meta from "./blocks/Meta.js";

function Projects() {
    return ( 
        <>
        <Meta page="projects" />
        <div className = "projects">
            <div className="row justify-content-center contetnSection pt-md-5 pt-3 pb-4">
                <div className="col-xl-12 col-lg-10 col-md-11 col-sm-11 col-11">
                    <div className="row justify-content-center contetnSection  pb-4">
                        <div className="col-xl-10 col-lg-7 col-md-11 col-sm-11 col-11 centerAlign px-0">
                            <div className=" mt-5">
                                <div className="title pb-2">
                                    Projects
                                </div>
                            </div>
                            <div className="description pb-2 mt-2">
                                At Ealing Developers, we are proud to showcase a diverse portfolio of completed and ongoing projects. From modern residential complexes to innovative commercial spaces, each project reflects our commitment to excellence, sustainability, and community impact.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className = "row justify-content-center contetnSection pb-4">
                <div className = "col-xl-10 col-lg-10 col-md-11 col-sm-11 col-11">
                    <div className = "row contetnSection pt-md-5 pt-0 pb-4 justify-content-center">
                        {projectsData.map((data, key)=>{
                            return(
                            <div className = "col-xl-5 col-lg-5 col-md-5 col-sm-6 col-11 position-relative card-element px-md-auto px-0 " key={key}>
                                <div className = "row justify-content-center">
                                { data.button=="true" && <NavLink to={'/projects/'+data.id}><div className = "col-12 projectElement">
                                        <div className='imageContiner'>
                                            <img src={process.env.PUBLIC_URL + data.image} alt=""/>
                                        </div>
                                        <div className='content pt-4'>
                                            <div className="card-title pb-2">
                                                {data.title}
                                            </div>
                                            <hr className="hrTitle"/>
                                            <div className="card-location pt-2 pb-2 d-flex">
                                                <div className="location-icon text-color-white"><img src={process.env.PUBLIC_URL + '/images/location.png'} alt="" /></div>
                                                {data.location}
                                            </div>
                                            <div className="card-description pt-2 pb-2 d-flex">
                                                {data.description}
                                            </div>
                                        </div>
                                    </div> </NavLink>}
                                </div>
                            </div>
                            );
                        })}

                    </div>
                </div>

            </div>
        </div>
        </>
    );
}

export default Projects;