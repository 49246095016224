
import '../App.css';
import './Home.scss';
import Meta from "./blocks/Meta.js";
import ContactForm from "./blocks/ContactForm.js";
function Home() {
    return (
        <>
        <Meta page="home" />
        <div className="home">
            <div className="row justify-content-center pt-5">
                <div className="col-xl-10 col-md-11 col-sm-11 col-11">
                    <div className="row justify-content-center heroSection">
                        <div className="col-xl-6 col-md-6 col-sm-11 col-11 sideContainer  ps-0">
                            <div className="leftBorder">
                                <div className="subtitle pb-2 pt-4">
                                    Ealing Developers designs exceptional residential and commercial environments that elevate modern living.
                                </div>
                                <div className="title pb-2 pt-5">
                                    Transforming Spaces. <br />Building Communities.
                                </div>
                                <a className="btn btn-dark btnExplore mt-5" href="/projects">&#8226; Explore now</a>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-6 col-sm-11 col-11 px-0 justify-content-end sideContainer mt-5 mt-md-0">
                            <img src={process.env.PUBLIC_URL + '/images/Frame 3.png'} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center mt-5 pt-md-5 pt-3">
                <div className="col-xl-10 col-md-11 col-sm-11 col-11 contentSection">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-xl-5 col-md-6 col-sm-11 col-11 ">
                            <div className="content pb-5   pt-md-5 pt-0">
                                Approved by Premier Guarantee and Build-Zone, we offer a 10-year warranty on all our projects.
                            </div>
                        </div>
                        <div className="col-xl-7 col-md-6 col-sm-11 col-11 ">
                            <div className="row contentSupply justify-content-center">
                                <div className="col-xl-4 col-md-4 col-sm-4 col-4 supply">
                                    <a target="_blank" href="http://www.premierguarantee.co.uk/" rel="noreferrer"><img className="w-100" src={process.env.PUBLIC_URL + '/images/pg.png'} alt="" /></a>
                                </div>
                                <div className="col-xl-4 col-md-4 col-sm-4 col-4 supply">
                                    <a target="_blank" href="http://www.premierguarantee.co.uk/" rel="noreferrer"><img className="w-100" src={process.env.PUBLIC_URL + '/images/pgS-1.png'} alt="" /></a>
                                </div>
                                <div className="col-xl-4 col-md-4 col-sm-4 col-4 supply">
                                    <a target="_blank" href="https://www.build-zone.com/" rel="noreferrer"><img className="w-100" src={process.env.PUBLIC_URL + '/images/buildz-2.png'} alt="" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center about-section">
                <div className="col-xl-10 col-md-11 col-sm-11 col-11">
                   
                    <div className="row justify-content-center ">
                        <div className="col-xl-5 col-md-6 col-sm-11 col-11 justify-content-end sideContainer pt-5 pb-md-5 pb-3 px-0">
                            <img src={process.env.PUBLIC_URL + '/images/22-1.jpg'} alt="" />
                        </div>
                        <div className="col-xl-7 col-md-6 col-sm-11 col-11 sideContainer pt-md-5 pt-3 px--md-auto px-0">
                        <div className="categoryTitle ps-md-3 ps-0 pb-2 pt-1">
                                About Us                            
                            </div>
                            <div className="content pb-2 pt-5">

                                At Ealing Developers, we create more than just buildings—we craft spaces that enrich the lives of those who live and work in them. With over 20 years of experience, we’ve earned a reputation for delivering innovative, sustainable projects in prime locations. Our focus on thoughtful design, community, and lasting value ensures each development enhances the quality of life for its residents and contributes to the wider community.
                            </div>
                            <a className="btn btn-dark btnExplore mt-5" href="/about">&#8226; See more</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row justify-content-center">
                <div className="col-xl-10 col-md-11 col-sm-11 col-11">
                    <div className="row justify-content-center ">
                        <div className="col-xl-7 col-md-6 col-sm-11 col-11 justify-content-end sideContainer  pt-5 px-0">
                            <div className="categoryTitle pb-2 pt-5">
                               Why Us?
                            </div>

                            <div className="row mt-md-4 mt-5">
                                <div className="col-xl-1 col-md-2 col-2">
                                    <img className="w-100" src={process.env.PUBLIC_URL + '/images/cardImages/design-ico.png'} alt="" />
                                </div>
                                <div className="col-10">
                                    <div className="titleCard">
                                        Design
                                    </div>
                                    
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-10 col-12">
                                    <div className="descCard">
                                        Our expert design team brings your vision to life, combining innovation and functionality to create outstanding spaces.
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-md-4 mt-5">
                                <div className="col-xl-1 col-md-2 col-2">
                                    <img className="w-100" src={process.env.PUBLIC_URL + '/images/cardImages/building-ico.png'} alt="" />
                                </div>
                                <div className="col-10">
                                    <div className="titleCard">
                                    Full Construction Services
                                    </div>
                                    
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-10 col-12">
                                    <div className="descCard">
                                    Our construction team delivers high-quality workmanship, using the latest techniques and materials to bring your project to life.
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-md-4 mt-5">
                                <div className="col-xl-1 col-md-2 col-2">
                                    <img className="w-100" src={process.env.PUBLIC_URL + '/images/cardImages/waranty-ico.png'} alt="" />
                                </div>
                                <div className="col-10">
                                    <div className="titleCard">
                                    10-Year Warranty
                                    </div>
                                    
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-10 col-12">
                                    <div className="descCard">
                                    We back our work with a comprehensive 10-year warranty, ensuring peace of mind and long-term investment security. 
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-5 col-md-6 col-sm-11 col-11 justify-content-end sideContainer headSection pt-5 px-0">
                            <div className="row pt-md-5 pt-3">
                                <div className="col-12 h-100">
                                    <img src={process.env.PUBLIC_URL + '/images/22-1.jpg'} alt="" />
                                </div>
                        
                            </div>
                       
                        </div>
                    </div>
                    
                    {/* <div className="row">
                        <div className="col-xl-12 col-md-6 col-sm-12 col-12 sideContainer d-flex align-items-center justify-content-end">
                            <a className="btn btn-dark btnSeeMore mt-5" href="/services">SEE MORE &#8599; </a>
                        </div>
                    </div> */}
                </div>
            </div>

            <div className="row justify-content-center mt-md-5 mt-3 project-section">
                <div className="col-xl-10 col-md-11 col-sm-11 col-11 mt-md-5 mt-3">
                    <div className="row justify-content-center">
                        <div className="col-xl-4 col-md-4 col-sm-11 col-11 px-0">

                            <div className="categoryTitle projectsTitle pb-2 pt-5">
                                Projects
                            </div>
                            <div className="contentProj mt-md-5 mt-3"> 
                                At Ealing Developers, we proudly present a diverse portfolio of residential and commercial projects, each reflecting our commitment to excellence, sustainability, and positive community impact.
                            </div>
                            <a className="btn btn-dark btnExploreProj mt-md-5 mt-3" href="/projects">&#8226; Explore now</a>
                            {/* <div className="col-xl-5 col-md-6 col-sm-6 col-4 justify-content-end sideContainer headSection pt-5 px-0">
                            <a className="btn btn-dark btnSeeMore mt-5 ml-5" href="/projects">SEE MORE &#8599; </a>
                        </div> */}

                        </div>
                        <div className="col-xl-8 col-md-8 col-sm-11 col-11">
                            <div className="row">

                                <div className="col-xl-6 col-md-6 col-sm-12 col-12 justify-content-end sideContainer projImage pt-5 pb-5 px-0 rightBorder">
                                    <img src={process.env.PUBLIC_URL + '/images/project_26.jpg'} alt="" />
                                </div>
                                <div className="col-xl-6 col-md-6 col-sm-12 col-12 sideContainer d-flex align-items-center">
                                    <div className="content pb-2 pt-md-5 pt-3">
                                        <div className="row ps-md-4">
                                            <div className="col-xl-12 col-md-12 col-sm-12 col-12 sideContainer d-flex align-items-center ">
                                                <div className="titleProject">
                                                    Development of 5 luxury flats in Ealing W3
                                                </div>

                                                <div className="descProject">
                                                    A development of five luxury flats located in Ealing, W3, offering high-end finishes, modern amenities, and spacious layouts designed for comfort and style.
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="row mt-2 ps-md-4">
                                            <div className="col-xl-12 col-md-6 col-sm-12 col-12 sideContainer d-flex align-items-center ">
                                                <div className="descProj">
                                                    This exclusive development is situated in Ealing W3, a desirable West London location known for its leafy surroundings, excellent transport links, and vibrant local amenities. The project comprises five meticulously designed luxury flats, offering residents a harmonious blend of contemporary living and traditional charm.
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="row">
                                            <div className="col-xl-12 col-md-12 col-sm-12 col-12 sideContainer d-flex align-items-left justify-content-start">
                                                <a className="btn btn-dark btnSeeMore mt-5" href="/projects">• SEE MORE</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="row borderTop pt-0">

                                <div className="col-xl-6 col-md-6 col-sm-12 col-12 justify-content-end sideContainer projImage pt-5 pb-5 px-0 rightBorder">
                                    <img src={process.env.PUBLIC_URL + '/images/Proj_2/286.jpg'} alt="" />
                                </div>
                                <div className="col-xl-6 col-md-6 col-sm-12 col-12 sideContainer d-flex align-items-center">
                                    <div className="content pb-2 pt-md-5 pt-3">
                                        <div className="row ps-md-4">
                                            <div className="col-xl-12 col-md-12 col-sm-12 col-12 sideContainer d-flex align-items-center ">
                                                <div className="titleProject">
                                                    Nine Luxury Flats in Golders Green, NW11
                                                </div>
                                                
                                                <div className="descProject">
                                                    A new luxury development of nine premium flats in Golders Green, NW11, with high-end finishes and prime location.
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="row mt-2 ps-md-4">
                                            <div className="col-xl-12 col-md-6 col-sm-12 col-12 sideContainer d-flex align-items-center ">
                                                <div className="descProj">
                                                    This exclusive development is situated in Ealing W3, a desirable West London location known for its leafy surroundings, excellent transport links, and vibrant local amenities. The project comprises five meticulously designed luxury flats, offering residents a harmonious blend of contemporary living and traditional charm.
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="row">
                                            <div className="col-xl-12 col-md-12 col-sm-12 col-12 sideContainer d-flex align-items-left justify-content-start">
                                                <a className="btn btn-dark btnSeeMore mt-5" href="/projects">• SEE MORE</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row justify-content-center">
                <div className="col-xl-10 col-md-10 col-sm-11 col-11">
                    <div className="row justify-content-center ">
                        <div className="col-xl-5 col-md-6 col-sm-12 col-11 justify-content-end sideContainer  pt-5 px-0">
                            <div className="categoryTitle pb-2 pt-md-5 pt-3">
                                Let's <br />
                                <span>Get in touch</span>
                            </div>
                        </div>
                        <div className="col-xl-7 col-md-6 col-sm-12 col-11 justify-content-end sideContainer pt-md-5 pt-3 px-0">
                            <ContactForm />
                        </div>
                    </div>
                    <div className="row">
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}

export default Home;