export const  metaData = {
    home: {
      title: "Home - Ealing Developers",
      description: "Discover Ealing Developers, where innovative design meets rich heritage. We create exceptional homes and commercial spaces that enhance local communities, blending modern architecture with Ealing's timeless charm."
    },
    about: {
      title: "About - Ealing Developers",
      description: "Discover Ealing Developers, where innovative design meets rich heritage. We create exceptional homes and commercial spaces that enhance local communities, blending modern architecture with Ealing's timeless charm."
    },
    projects: {
        title: "Projects - Ealing Developers",
        description: "Discover Ealing Developers, where innovative design meets rich heritage. We create exceptional homes and commercial spaces that enhance local communities, blending modern architecture with Ealing's timeless charm."
    },
    contact: {
        title: "Contact - Ealing Developers",
        description: "Discover Ealing Developers, where innovative design meets rich heritage. We create exceptional homes and commercial spaces that enhance local communities, blending modern architecture with Ealing's timeless charm."
    },
    services: {
        title: "Services - Ealing Developers",
        description: "Discover Ealing Developers, where innovative design meets rich heritage. We create exceptional homes and commercial spaces that enhance local communities, blending modern architecture with Ealing's timeless charm."
    },
    suppliers: {
        title: "Suppliers - Ealing Developers",
        description: "Discover Ealing Developers, where innovative design meets rich heritage. We create exceptional homes and commercial spaces that enhance local communities, blending modern architecture with Ealing's timeless charm."
    }, 
    // Add more pages as needed
  };