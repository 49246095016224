import './About.scss';
import { useEffect } from "react";
import { useLocation  } from "react-router-dom";
import MapComponent from './blocks/MapComponent';
import ContactForm from "./blocks/ContactForm.js";
import Meta from "./blocks/Meta.js";
function About() {
    const location = useLocation();

  useEffect(() => {
    // Scroll to the "contact-us" section if the hash exists
    if (location.hash === "#contact-us") {
      const element = document.getElementById("contact-us");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
    return (
        <>
        <Meta page="about" />
        <div className="about">

            <div className="row justify-content-center contetnSection mt-5 pt-5 pb-4">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="row justify-content-center align-items-center contetnSection mt-5 pb-4">
                        <div className="col-xl-7 col-lg-7 col-md-11 col-sm-11 col-11">
                            <div className="categoryTitle pb-2 pt-5">
                                About <br />
                                <span>Ealing Developers</span>
                            </div>
                            <div className='description mt-4'>
                                At Ealing Developers, our mission is to design and build exceptional homes and commercial spaces that contribute to the growth and prosperity of the local community. We believe in blending cutting-edge architecture with the rich heritage of Ealing to develop properties that inspire and uplift.
                            </div>
                        </div>
                        <div className="col-xl-5 col-lg-7 col-md-11 col-sm-11 col-11 ">
                            <img src={process.env.PUBLIC_URL + '/images/about-banner.png'} alt="" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row justify-content-center contetnSection  pb-4">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-11 col-11">
                    <div className="service-title mb-3">
                        Why Choose Us?
                    </div>
                    <div className='description'>
                        Choosing Ealing Developers means partnering with a company that values quality, innovation, and community above all. We work closely with our clients to understand their needs and deliver projects that exceed expectations. From the first consultation to the final touches, we are with you every step of the way.
                    </div>
                </div>
            </div>

            <div className="row justify-content-center align-items-center contetnSection pt-5 pb-4">
                <div className="col-xl-6 col-lg-10 col-md-11 col-sm-11 col-11">
                    <div className="row justify-content-center">
                        <div className="service-title mb-3">
                            Contact Details
                        </div>
                        <div className='description'>
                        </div>
                    </div>
                    <div className="row justify-content-start contetnSection pt-5 pb-4">
                        <div className="titleCard">
                            Ealing Developers Ltd
                        </div>
                        <div className="description">
                            Reg no: 10660181<br/>
                            Reg address:<br/>
                            Suite 4, Mill Hill House<br/>
                            6 The Broadway<br/>
                            London<br/>
                            NW7 3LL<br/>
                            E: manager@ealingdevelopers.com
                        </div>
                        
                    </div>
                </div>
                <div className="col-xl-6 col-lg-10 col-md-11 col-sm-11 col-11">
                    <MapComponent />
                </div>
            </div>

        

            <div className="row justify-content-center " id="contact-us">
                <div className="col-xl-12 col-md-10 col-sm-11 col-11 mt-md-5 mt-0">
                    <div className="row justify-content-center">
                        <div className="col-xl-5 col-md-6 col-sm-11 col-11 justify-content-end sideContainer  pt-5 px-0">
                            <div className="service-title pb-2 pt-md-5 pt-0 ">
                                Let's <br />
                                <span>Get in touch</span>
                            </div>
                        </div>
                        <div className="col-xl-7 col-md-6 col-sm-11 col-11 justify-content-end sideContainer pt-md-5 pt-0 px-0">
                            <ContactForm />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        </>
    );
}

export default About;