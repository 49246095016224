import "./FooterMenu.scss";
import { NavLink } from "react-router-dom";

function FooterMenu() {

    return (
        <footer className=" text-black text-center text-lg-start borderTop">
            <div className="container-fluid ps-5 pe-5 pt-3 pb-3">

                <div className="row justify-content-center">
                    <div className="col-lg-10 col-md-12 mb-4 mb-md-0">
                      


                    </div>
                </div>
                <div className="row  justify-content-center">
                    <div className="col-xl-10 col-lg-12 col-md-12 col-sm-12 col-12 mb-4 mb-md-0">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12 col-12 mb-5 mb-md-0 mt-4">
                            <h5 className="text-uppercase title justify-content-left">LET'S BUILD
                                <span> SOMETHING WONDERFUL</span>
                            </h5>
                                Discover Ealing's vibrant development projects, fostering growth, sustainability, and community innovation. Explore opportunities, updates, and initiatives shaping the future of our borough.
                                
                               
                            </div>
                            <div className="col-lg-5 col-md-6 col-sm-12 col-12 mb-4 mb-md-0 columnSide d-flex justify-content-end">
                                <div className="row">
                                <ul className="list-unstyled mb-0 d-flex">
                                    <li className="nav-item">
                                        <NavLink className="nav-link" to="/">Home</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink className="nav-link" to="/about">About</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink className="nav-link" to="/projects">Projects</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink className="nav-link" to="/services">Services</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink className="nav-link" to="/suppliers">Suppliers</NavLink>
                                    </li>
                                </ul>
                                </div>
                                <a className="btn btn-dark btnExplore mt-md-5 mt-0" href="/projects">&#8226; Explore now</a>
                             
                                
                            </div>
                        </div>

                    </div>
                </div>
           

                <div className="row justify-content-start borderTop mt-md-5 mt-0 ">

                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 mb-md-0 d-flex align-items-center justify-content-center">

                        <div className="text-lefrcopyright pt-3">
                            © 2024 EALING DEVELOPERS: All rights reserved | Powered by LEIO SOFTWARE
                        </div>
                    </div>
                </div>
            </div>

        </footer>
    );
}

export default FooterMenu;