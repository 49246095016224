import React from "react";
import { useParams } from "react-router-dom";
import "./Project.scss"
import ImageGallery from "react-image-gallery";
import { projectElements } from "../../data/elements.js"
import 'react-image-gallery/styles/css/image-gallery.css';
function Project() {
    const params = useParams();
    const element = { ...projectElements[params.id] };
    const images = element.gallery;
    return (
        <div className="project">
            
            <div className="row justify-content-center contetnSection pb-4">
             
                <div className="col-md-8 col-11">
          
                    <div className="row justify-content-left contetnSection pb-4">
                    <a className="btn btnBack  mb-1" href="/projects">⟵ Back</a>
                    <div className="title pb-4">
                                {element.title}
                            </div>
                            <div className='description-section'>
                                
                                <div className='description-section-text'>
                                    {/* <div className='details'>
                                        <b>Visit:</b> <a href={element.link}>{element.link}</a>
                                    </div> */}

                                    <div className='details'>
                                        <b>Location:</b> {element.location}
                                    </div>

                                    <div className='details'>
                                        <b>Type:</b> {element.type}
                                    </div>

                                    <div className='details'>
                                        <b>Units:</b> {element.units}
                                    </div>   
                                    <div className='description'>
                            {element.description}
                        </div>   
                                </div> 
                                <ImageGallery items={images} />
                            </div> 
                    </div>

                    <div className="row justify-content-center contetnSection  pb-4">
                 

                    </div>
                </div>
            </div>




        </div>
    );
}

export default Project;
