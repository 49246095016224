import React from "react";
import './Suppliers.scss';
import {suppliersData} from "../data/suppliers.js"
import Meta from "./blocks/Meta.js";

function Suppliers() {
    return ( 
        <>
        <Meta page="suppliers" />
        <div className = "suppliers">
            <div className="row justify-content-center contetnSection pt-5 pb-4">
                <div className="col-xl-12 col-lg-10 col-md-11 col-sm-11 col-11">
                    <div className="row justify-content-center contetnSection  pb-4">
                        <div className="col-xl-10 col-lg-7 col-md-11 col-sm-11 col-11">
                            <div className="leftBorder">
                                <div className="title pb-2">
                                    Suppliers
                                </div>
                           
                            </div>
                        </div>


                    </div>
                </div>

            </div>
            <div className = "row justify-content-center contetnSection pb-4">
                <div className = "col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12">
                    <div className = "row contetnSection pt-md-4 pt-0 pb-4 justify-content-center">
                        {suppliersData.map((data, key)=>{
                            return(
                            <div className = "col-xl-4 col-lg-4 col-md-6 col-sm-6 col-11 position-relative justifyContent center" key={key}>
                                <div className = "row justify-content-center">
                                    <div className = "col-11 projectElement">
                                        <div className='imageContiner'>
                                            <img src={process.env.PUBLIC_URL + data.image}/>
                                        </div>
                                        <div className='content pt-4'>
                                            <div className="card-title pb-4">
                                                {data.title}
                                            </div>
                                            <a className="btn btn-dark" href={data.link}>Visit Site</a>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            );
                        })}

                    </div>
                </div>

            </div>
        </div>
        </>
    );
}

export default Suppliers;